import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AccountTab from '../components/AccountTab';
import { actionCreators } from '../store/auth/actions';
import { injectIntl } from 'react-intl';

const IfbetView = ({ order, item, index, sportGameDescription, gameDate }) => {
    const gameTime = new Date(order.items[0].gameDateSeconds * 1000);
    const placedTime = new Date(order.items[0].placedDateSeconds * 1000);
    const placedDateSeconds = new Date(order.placedDate * 1000);
    return (
        <Fragment>
            <tr className='borderless-table' style={{ borderTop: '1px solid white' }}>
            </tr>
            <tr className="borderless-table">
                <td>{order.idWager}</td>
                <td>{order.userName}</td>
                <td>{placedDateSeconds.toLocaleDateString()} {placedDateSeconds.toLocaleTimeString()}</td>
                <td>{order.desc}</td>
                <td className="empty-cell"></td>
                <td>{order.risk}/{order.toWin}</td>

            </tr>
            <tr className='borderless-table'>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{sportGameDescription}</td>
                <td>{gameDate}</td>
                <td className="empty-cell"></td>
            </tr>
            <tr>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{order.items[0].idSport} {order.items[0].desc}</td>
                <td>{gameTime.toLocaleDateString()} {gameTime.toLocaleTimeString()}</td>
                <td className="empty-cell"></td>

            </tr>
            <tr className="borderless-table">
                <td>{order.idWager}</td>
                <td>{order.userName}</td>
                <td>{placedTime.toLocaleDateString()} {placedTime.toLocaleTimeString()}</td>
                <td>THEN STRAIGHT BET</td>
                <td className="empty-cell"></td>
                <td>{order.risk}/{order.toWin}</td>

            </tr>
            <tr className='borderless-table'>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{sportGameDescription}</td>
                <td>{gameDate}</td>
                <td className="empty-cell"></td>
            </tr>
            <tr>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{order.items[1].idSport} {order.items[1].desc}</td>
                <td>{order.items[1].gameDate} {order.items[1].gameTime}</td>
                <td className="empty-cell"></td>

            </tr>
        </Fragment>
    );
};

const ParlayView = ({ order, item, index, sportGameDescription, gameDate }) => {
    const placedDateSeconds = new Date(order.placedDate * 1000);
    return (
        <Fragment>
            <tr className='borderless-table' style={{ borderTop: '1px solid white' }}>
            </tr>
            <tr className="borderless-table">
                <td>{order.idWager}</td>
                <td>{order.userName}</td>
                <td>{placedDateSeconds.toLocaleDateString()} {placedDateSeconds.toLocaleTimeString()}</td>
                <td>{order.desc}</td>
                <td className="empty-cell"></td>
                <td>{order.risk}/{order.toWin}</td>

            </tr>
            <tr className='borderless-table'>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{sportGameDescription}</td>
                <td>{gameDate}</td>
                <td className="empty-cell"></td>
            </tr>
            <Fragment>
                {
                    order.items.map((item, index) => {
                        if (index !== order.items.length - 1) {
                            return (
                                <tr className='borderless-table' key={index}>
                                    <td className="empty-cell"></td>
                                    <td className="empty-cell"></td>
                                    <td className="empty-cell"></td>
                                    <td>{item.idSport} {item.desc}</td>
                                    <td>{item.gameDate} {item.gameTime}</td>
                                    <td className="empty-cell"></td>

                                </tr>
                            )
                        }
                        else {
                            return (
                                <tr key={index}>
                                    <td className="empty-cell"></td>
                                    <td className="empty-cell"></td>
                                    <td className="empty-cell"></td>
                                    <td>{item.idSport} {item.desc}</td>
                                    <td>{item.gameDate} {item.gameTime}</td>
                                    <td className="empty-cell"></td>

                                </tr>)
                        }
                    }
                    )
                }
            </Fragment>


        </Fragment>
    );
}

const ReverseView = ({ order, item, index, sportGameDescription, gameDate }) => {
    const placedDateSeconds = new Date(order.placedDate * 1000);
    return (
        <Fragment>
            <tr className='borderless-table' style={{ borderTop: '1px solid white' }}>
            </tr>
            <tr className="borderless-table">
                <td>{order.idWager}</td>
                <td>{order.userName}</td>
                <td>{placedDateSeconds.toLocaleDateString()} {placedDateSeconds.toLocaleTimeString()}</td>
                <td>{order.desc}</td>
                <td className="empty-cell"></td>
                <td>{order.risk}/{order.toWin}</td>

            </tr>
            <tr className='borderless-table'>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td className="empty-cell"></td>
                <td>{sportGameDescription}</td>
                <td>{gameDate}</td>
                <td className="empty-cell"></td>
            </tr>
            <Fragment>
                {
                    order.items.map((item, index) => {
                        if (index !== order.items.length - 1) {
                            return (
                                <Fragment>
                                    <tr className='borderless-table'>
                                        <td className="empty-cell"></td>
                                        <td className="empty-cell"></td>
                                        <td className="empty-cell"></td>
                                        <td>{item.idSport} {item.desc}</td>
                                        <td>{item.gameDate} {item.gameTime}</td>
                                        <td className="empty-cell"></td>

                                    </tr>
                                </Fragment>
                            )
                        }
                        else {
                            return (
                                <Fragment>
                                    <tr>
                                        <td className="empty-cell"></td>
                                        <td className="empty-cell"></td>
                                        <td className="empty-cell"></td>
                                        <td>{item.idSport} {item.desc}</td>
                                        <td>{item.gameDate} {item.gameTime}</td>
                                        <td className = "empty-cell" ></td>

                                    </tr>
                                </Fragment>)
                        }
                    }
                    )
                }
            </Fragment>


        </Fragment>
    );
}

const ItemView = ({ order, item, index, sportGameDescription, gameDate }) => {
    if (index === 0) {
        const lowerDesc = order.desc.toLowerCase();
        if (lowerDesc.search(/if win/) !== -1) {
            return (
                <IfbetView key={`${order.idWager}_${index}`} order={order} item={item} index={index} sportGameDescription={sportGameDescription} gameDate={gameDate } />)
        }
        else if (lowerDesc.search(/parlay/) !== -1) {
            return (
                <ParlayView key={`${order.idWager}_${index}`} order={order} item={item} index={index} sportGameDescription={sportGameDescription} gameDate={gameDate} />
            );
        }
        else if (lowerDesc.search(/teaser/) !== -1) {
            return (
                <ParlayView key={`${order.idWager}_${index}`} order={order} item={item} index={index} sportGameDescription={sportGameDescription} gameDate={gameDate } />
            );
        }
        else if (lowerDesc.search(/reverse/) !== -1) {
            return (
                <ReverseView key={`${order.idWager}_${index}`} order={order} item={item} index={index} sportGameDescription={sportGameDescription} gameDate={gameDate} />
            );
        }
    };
};

const SimpleOrderSummaryView = ({ totalRisk, totalToWin }) => {
    return (
        <Fragment>
            <tr className='borderless-table' style={{ borderTop: '1px solid white' }}>
                <td></td>
                <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'right', fontSize: '1.2em' }}>
                    Risk: {totalRisk} | Win: {totalToWin}
                </td>
            </tr>
        </Fragment>
    );
};




const OrderSummaryView = ({ order, sportGameDescription, gameDate }) => {
    if (order.items.length === 1) {
        const gameTime = new Date(order.items[0].gameDateSeconds * 1000);
        const placedDate = new Date(order.placedDateSeconds * 1000);
        return (
            <Fragment>
                <tr className='borderless-table' style={{ borderTop: '1px solid white' }}>
                </tr>
                <tr className='borderless-table'>
                    <td>{order.idWager}</td>
                    <td>{order.userName}</td>
                    <td>{placedDate.toLocaleDateString()} {placedDate.toLocaleTimeString()}</td>
                    <td>{order.desc}</td>
                    <td className="empty-cell"></td>
                    <td>{order.risk}/{order.toWin}</td>
                </tr>
                <tr className='borderless-table'>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td>{sportGameDescription}</td>
                    <td>{gameDate}</td>
                    <td className="empty-cell"></td>
                </tr>
                <tr>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td>{order.items[0].idSport} {order.items[0].desc}</td>
                    <td>{gameTime.toLocaleDateString()} {gameTime.toLocaleTimeString()}</td>
                    <td className="empty-cell"></td>

                </tr>

            </Fragment>
        );
    }

    return (
        <Fragment>
            {order.items.map((item, index) => (<ItemView key={`${order.idWager}_${index}`} order={order} item={item} index={index} sportGameDescription={sportGameDescription} gameDate={gameDate } />))
            }
        </Fragment>);
};

class UnsettledBets extends Component {
    static displayName = UnsettledBets.name;

    constructor(props) {
        super(props);
        this.state = {
            history: [],
            mode: 0,
            loading: true
        };
        this.toggleSettleMode = this.toggleSettleMode.bind(this);
    }

    componentDidMount() {
        this.populateData(this.state.mode);
    }

    toggleSettleMode(mode) {
        this.setState(prevState => ({
            mode: mode,
            loading: true
        }));
        this.populateData(mode);
    }

    render() {
        const openWagersForPlayer = this.props.intl.formatMessage({ id: 'openWagersForPlayer' });
        const sportGameDescription = this.props.intl.formatMessage({ id: 'sportGameDescription' });
        const datePlaced = this.props.intl.formatMessage({ id: 'datePlaced' });
        const description = this.props.intl.formatMessage({ id: 'description' });
        const riskWin = this.props.intl.formatMessage({ id: 'riskWin' });
        const userPhone = this.props.intl.formatMessage({ id: 'userPhone' });
        const gameDate = this.props.intl.formatMessage({ id: 'gameDate' });

        let totalRisk = 0;
        let totalToWin = 0;

        if (!this.state.loading && this.state.history) {
            this.state.history.forEach(order => {
                totalRisk += order.risk; 
                totalToWin += order.toWin; 
            });
        }

        return (
            <Fragment>
                <AccountTab />

                <div className="text-center">
                    <h4>{openWagersForPlayer} {this.props.userName}</h4>
                </div>

                <div className="table-responsive">
                    <table className="container">
                        <thead>
                            <tr>
                                <th scope="col" style={{ minWidth: '100px' }}>#</th>
                                <th scope="col" style={{ minWidth: '100px' }}>{userPhone}</th>
                                <th scope="col" style={{ minWidth: '100px' }}>{datePlaced}</th>
                                <th scope="col" style={{ minWidth: '100px' }}>{description}</th>
                                <th scope="col" style={{ minWidth: '100px' }}>&nbsp;</th>
                                <th scope="col" style={{ minWidth: '100px' }}>{riskWin}</th>
                            </tr>
                        </thead>
                        {!this.state.loading && this.state.history &&
                            <tbody className="table-group-divider">
                                {this.state.history.map((order, index) => (
                                    <OrderSummaryView
                                        key={index}
                                        order={order}
                                        sportGameDescription={sportGameDescription}
                                        gameDate={gameDate}
                                    />
                                ))}
                            </tbody>
                        }
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="container">
                        <thead>
                            <SimpleOrderSummaryView
                                totalRisk={totalRisk}
                                totalToWin={totalToWin}
                            />
                        </thead>
                    </table>
                </div>
            </Fragment>
        );
    }


    async populateData(mode) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${this.props.token}`
            }
        };

        const response = await fetch(`api/v1/players/${mode}/openbets`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            this.setState({ history: data, loading: false });
        } else {
            if (response.status === 401) {
                this.props.logout();
            }
            this.setState({ loading: false });
        }
    }
}


function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName
    };
}

const mapDispatchToProps = {
    logout: actionCreators.resetState
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnsettledBets));